export interface RegisterUserDTO {
  name: string;
  email: string;
  password: string;
  contact?: string;
  agent?: boolean;
  registerCompanyDTO?: {
    companyName: string;
    companyEmail: string;
    companyPhone: string;
  };
}
export interface UserDTO {
  id: string;
  contact: string;
  name: string;
  email: string;
  confirmedEmail: boolean;
  agent: boolean;
  password: string; //changes need to be made
}

export interface UserProfileInfo {
  id: string;
  name: string;
  email: string;
  contact: string;
  agent: boolean;
  companyDTO?: {
    companyName: string;
    companyEmail: string;
    companyPhone: string;
  };
}

export interface UserPropertyInfo {
  id: string;
  name: string;
  email: string;
  contact: string;
  agent: boolean;
  company?: {
    companyName: string;
    companyEmail: string;
    companyPhone: string;
  };
}
export interface BaseProperty {
  id: string;
  user: UserPropertyInfo;
  location?: LocationDTO;
  locationId?: string;
  title: string;
  address: string;
  phoneNumber: string,
  price: number;
  image?: ImageDTO;
  electricity: boolean;
  gas: boolean;
  water: boolean;
  sewerage: boolean;
  asphaltRoad: boolean;
  description: string;
  sellerType: string;
  sellOrRent: SellOrRent;
  isActive: boolean;
  isArchived: boolean;
  createdAt: string;
  updatedAt: string;
  favoriteId?: string;
  category?:string;
}

export interface PaginatedProps {
  totalElements: number;
  props: BaseProperty[];
}
interface BaseBuilding extends BaseProperty {
  usefulSurface: number;
  buildSurface: number;
  constructionYear: number;
  nrRooms: number;
  nrBathrooms: number;
  nrKitchens: number;
}
export interface ApartmentDTO extends BaseBuilding {
  story: number;
  stories: number;
  garageOrParking: boolean;
}
export interface HouseDTO extends BaseBuilding {
  landSurface: number;
  stories: number;
  garageOrParking: boolean;
}
export interface LandDTO extends BaseProperty {
  usefulSurface: number;
  classification: string;
  landType: LandType;
}
export interface OfficeDTO extends BaseBuilding {
  landSurface: number;
  stories: number;
  airConditioned: boolean;
  kitchen: boolean;
  dvCabling: boolean;
  heavyCurrent: boolean;
  accessible: boolean;
  lift: boolean;
  garageOrParking: boolean;
}
export interface IndustrialDTO extends Omit<BaseBuilding, 'nrRooms'> {
  landSurface: number;
  hall: boolean;
  industrialHall: boolean;
  coldStore: boolean;
  bearingSurface: boolean;
  surfaceArea: boolean;
  deposit: boolean;
  workshop: boolean;
  ramp: boolean;
  liftingPlatform: boolean;
  craneRunway: boolean;
}
export interface CommercialDTO extends BaseBuilding {
  landSurface: number;
  stories: number;
  garageOrParking: boolean;
  isCommision: boolean;
  isKiosk: boolean;
  isMall: boolean;
  isSalesArea: boolean;
}

export type Property = HouseDTO &
  LandDTO &
  ApartmentDTO &
  OfficeDTO &
  CommercialDTO &
  IndustrialDTO;

interface BaseFavoriteProperty {
  id: string;
  user: UserPropertyInfo;
}
interface FavoriteOfficeDTO extends BaseFavoriteProperty {
  // bad swagger docs?
  office: OfficeDTO;
}
interface FavoriteLandDTO extends BaseFavoriteProperty {
  land: LandDTO;
}
interface FavoriteIndustrialDTO extends BaseFavoriteProperty {
  industrial: IndustrialDTO;
}
interface FavoriteHouseDTO extends BaseFavoriteProperty {
  house: HouseDTO;
}
interface FavoriteCommercialDTO extends BaseFavoriteProperty {
  commercial: CommercialDTO;
}
interface FavoriteApartmentDTO extends BaseFavoriteProperty {
  apartment: ApartmentDTO;
}

export interface FavoriteProperty extends BaseFavoriteProperty {
  property: BaseProperty;
}

export enum PropertyType {
  Apartment = 'apartment',
  House = 'house',
  Land = 'land',
  Commercial = 'commercial',
  Industrial = 'industrial',
  Office = 'office',
}
export enum PropertySearchType {
  Apartment = 'Apartments',
  House = 'Houses',
  Land = 'Lands',
  Office = 'Offices',
  Commercial = 'Commercials',
  Industrial = 'Industrials',
}

export enum SellOrRent {
  Sell = 'Sell',
  Rent = 'Rent',
}
export enum LandType {
  Intravilan = 'Intravilan',
  Extravilan = 'Extravilan',
}
export interface SelectedImage {
  url: string;
  file?: File;
  name?: string;
}
export interface LocationDTO {
  id: string;
  name: string;
  localName: string;
  county: string;
  countyCode: string;
  zipCode: number;
  population: number;
  lat: number;
  lng: number;
}
export interface ImageDTO {
  name: string;
  original: string;
  thumbnail: string;
  principal: boolean;
}

export enum SortType {
  descending = 'DESC',
  ascending = 'ASC',
}
export interface QueryPaginationDTO {
  page: number;
  pageSize: number;
  sort: SortType;
  sortedByField: string;
}

export interface LandQueryDTO extends QueryPaginationDTO {
  county: string;
  locationName: string;
  minPrice: number;
  maxPrice: number;
  minUsefulSurface: number;
  maxUsefulSurface: number;
  electricity: boolean;
  gas: boolean;
  water: boolean;
  sewerage: boolean;
  asphaltRoad: boolean;
  sellOrRent: SellOrRent;
  classification: string;
  type: LandType;
}

export interface HouseQueryDTO extends QueryPaginationDTO {
  county: string;
  locationName: string;
  minPrice: number;
  maxPrice: number;
  minUsefulSurface: number;
  maxUsefulSurface: number;
  electricity: boolean;
  gas: boolean;
  water: boolean;
  sewerage: boolean;
  asphaltRoad: boolean;
  sellOrRent: SellOrRent;
  buildSurface: number;
  landSurface: number;
  minConstructionYear: number;
  maxConstructionYear: number;
  minNrRooms: number;
  maxNrRooms: number;
  nrBathrooms: number;
  nrKitchens: number;
  minStory: number;
  maxStory: number;
  garageOrParking: boolean;
}

export interface ApartmentQueryDTO extends QueryPaginationDTO {
  county: string;
  locationName: string;
  minPrice: number;
  maxPrice: number;
  minUsefulSurface: number;
  maxUsefulSurface: number;
  electricity: boolean;
  gas: boolean;
  water: boolean;
  sewerage: boolean;
  asphaltRoad: boolean;
  sellOrRent: SellOrRent;
  buildSurface: number;
  landSurface: number;
  minConstructionYear: number;
  maxConstructionYear: number;
  minNrRooms: number;
  maxNrRooms: number;
  nrBathrooms: number;
  nrKitchens: number;
  minStory: number;
  maxStory: number;
  stories: number;
  garageOrParking: boolean;
}
export interface IndustrialQueryDTO extends QueryPaginationDTO {
  county: string;
  locationName: string;
  minPrice: number;
  maxPrice: number;
  minUsefulSurface: number;
  maxUsefulSurface: number;
  electricity: boolean;
  gas: boolean;
  water: boolean;
  sewerage: boolean;
  asphaltRoad: boolean;
  sellOrRent: SellOrRent;
  buildSurface: number;
  landSurface: number;
  minConstructionYear: number;
  maxConstructionYear: number;
  nrBathrooms: number;
  nrKitchens: number;
  hall: boolean;
  industrialHall: boolean;
  coldStore: boolean;
  bearingSurface: boolean;
  surfaceArea: boolean;
  deposit: boolean;
  workshop: boolean;
  ramp: boolean;
  liftingPlatform: boolean;
  //sillyElevator: boolean;
  craneRunway: boolean;
}

export interface CommercialQueryDTO extends QueryPaginationDTO {
  county: string;
  locationName: string;
  minPrice: number;
  maxPrice: number;
  minUsefulSurface: number;
  maxUsefulSurface: number;
  electricity: boolean;
  gas: boolean;
  water: boolean;
  sewerage: boolean;
  asphaltRoad: boolean;
  sellOrRent: SellOrRent;
  buildSurface: number;
  landSurface: number;
  minConstructionYear: number;
  maxConstructionYear: number;
  minNrRooms: number;
  maxNrRooms: number;
  nrBathrooms: number;
  nrKitchens: number;
  minStory: number;
  maxStory: number;
  garageOrParking: boolean;
  isCommision: boolean;
  isMall: boolean;
  isSalesArea: boolean;
  isKiosk: boolean;
}

export interface OfficeQueryDTO extends QueryPaginationDTO {
  county: string;
  locationName: string;
  minPrice: number;
  maxPrice: number;
  minUsefulSurface: number;
  maxUsefulSurface: number;
  electricity: boolean;
  gas: boolean;
  water: boolean;
  sewerage: boolean;
  asphaltRoad: boolean;
  sellOrRent: SellOrRent;
  buildSurface: number;
  landSurface: number;
  minConstructionYear: number;
  maxConstructionYear: number;
  minNrRooms: number;
  maxNrRooms: number;
  nrBathrooms: number;
  nrKitchens: number;
  stories: number;
  garageOrParking: boolean;
  airConditioned: boolean;
  kitchen: boolean;
  dvCabling: boolean;
  heavyCurrent: boolean;
  accessible: boolean;
  lift: boolean;
}

export interface UserApartmentInfo {
  // to be deprecated ?
  apartmentId: string;
  county: string;
  town: string;
  title: string;
  price: number;
  dateAdded: Date;
  sellOrRent: string;
  active: boolean;
  saved: boolean;
}
export interface UserLandInfo {
  // same
  landId: string;
  county: string;
  town: string;
  title: string;
  price: number;
  dateAdded: Date;
  sellOrRent: string;
  active: boolean;
  saved: boolean;
}
export interface UserHouseInfo {
  // same
  houseId: string;
  county: string;
  town: string;
  title: string;
  price: number;
  dateAdded: Date;
  sellOrRent: string;
  active: boolean;
  saved: boolean;
}
export interface UserFavoritesInfo {
  // deprecated?
  houses: UserHouseInfo[];
  apartments: UserApartmentInfo[];
  lands: UserLandInfo[];
}
