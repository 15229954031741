import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, of, switchMap } from 'rxjs';
import { AuthTokenService } from '../../authentication/services/auth-token.service';
import {
  BaseProperty,
  FavoriteProperty,
  PaginatedProps,
  PropertySearchType,
  SortType,
} from '../data';
import { environment } from 'src/environments/environment';
import { BackendEndPointService } from 'src/app/services/back-endpoint.service';
import { determinator } from '../util';

@Injectable({
  providedIn: 'root',
})
export class UserPropertiesService {
  beURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private authTokenService: AuthTokenService,
    private backEndEndPointService: BackendEndPointService
  ) {}

  getSuggestedLocation(letters: string): Observable<any> {
    return this.backEndEndPointService.searchLocation(letters);
  }

  getProperties(
    type: PropertySearchType,
    count?: number
  ): Observable<BaseProperty[]> {
    return this.backEndEndPointService.getProperties(type, count);
  }

  getProperty(id: string, type: PropertySearchType): Observable<BaseProperty> {
    return this.authTokenService.getAccessToken().pipe(
      catchError((err) => {
        return of(undefined);
      }),
      switchMap((token) => {
        return this.backEndEndPointService.getProperty(id, type, token);
      })
    );
  }

  getUserProperties(
    type: PropertySearchType,
    page: number,
    pageSize: number
  ): Observable<PaginatedProps> {
    return this.authTokenService
      .getAccessToken()
      .pipe(
        switchMap((token) =>
          this.backEndEndPointService.getUserProperties(
            type,
            token,
            page,
            pageSize
          )
        )
      );
  }

  addProperty(baseProperty: BaseProperty, type: string) {
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.addProperty(
          baseProperty,
          type,
          token
        );
      })
    );
  }

  updateProperty(property: BaseProperty): Observable<any> {
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.updateProperty(property, token);
      })
    );
  }

  deleteProperty(property: BaseProperty) {
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.deleteProperty(property, token);
      })
    );
  }

  getPropertyImages(property: BaseProperty): Observable<any[]> {
    return this.backEndEndPointService.getPropertyImages(property);
  }

  deletePropertyImage(
    property: BaseProperty,
    imageName: string
  ): Observable<any> {
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.deletePropertyImage(
          property,
          imageName,
          token
        );
      })
    );
  }

  getFavorites(
    propertySearchType: PropertySearchType
  ): Observable<FavoriteProperty[]> {
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.getFavorites(
          propertySearchType,
          token
        );
      })
    );
  }

  deleteFavorite(favoriteId: string, property: BaseProperty, type?: string) {
    type = type ?? determinator(property).toLowerCase();
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.deleteFavorite(
          favoriteId,
          type!,
          token
        );
      })
    );
  }

  addFavorite(property: BaseProperty, type?: string) {
    type = type ?? determinator(property).toLowerCase();
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.addFavorite(
          property.id,
          type!,
          token
        );
      })
    );
  }
}
