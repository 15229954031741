import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if(!environment.production){
  console.log("develop mode");
}
if(environment.production){
  console.log("https://www.imolimo.ro/");
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
