import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  ngOnInit(): void {
  }

  @Input() isLoggedIn: boolean = false;
  terms: { id: string; header: string; items: { routerLink: string, text: string, isVisible: boolean }[] } = {
    id: '1', header: 'imolimo.ro', items: [
      { routerLink: "['privacy-policy']", text: 'app-user-dashboard.privacy-policy', isVisible:true },
      { routerLink: "['terms-conditions']", text: 'app-user-dashboard.terms-conds', isVisible:true },
      { routerLink: "https://www.anpc.ro", text: 'ANPC', isVisible:true }
    ]
  };

  links: { id: string; header: string; items: { routerLink: string, text: string, isVisible: boolean }[] } = {
    id: '2', header: 'Links', items: [
      { routerLink: "/search/apartments", text: 'app-user-dashboard.search-apartments', isVisible:true },
      { routerLink: "/search/houses", text: 'app-user-dashboard.search-houses', isVisible:true },
      { routerLink: "/search/lands", text: 'app-user-dashboard.search-lands', isVisible:true },
      { routerLink: "/search/offices", text: 'app-user-dashboard.search-offices', isVisible:true },
      { routerLink: "/search/commercials", text: 'app-user-dashboard.search-commercials', isVisible:true },
      { routerLink: "/search/industrials", text: 'app-user-dashboard.search-industrials', isVisible:true }
    ]
  }

  account: { id:string; header: string; items: { routerLink: string, text: string, isVisible: boolean }[] } = {
    id: '3', header: 'authentication.account', items: [
      { routerLink: "authentication/register-page", text: 'app-user-dashboard.user-conectivity.create-account', isVisible: !this.isLoggedIn},
      { routerLink: "authentication/login-page", text: 'app-user-dashboard.user-conectivity.button-text', isVisible: !this.isLoggedIn },
      { routerLink: "/add-property", text: 'app-user-dashboard.user-conectivity.add-property', isVisible: this.isLoggedIn }
    ]
  }

}
