import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from "@angular/router";
import { ApartmentDTO, BaseProperty, CommercialDTO, HouseDTO, IndustrialDTO, LandDTO, OfficeDTO, PropertySearchType } from "./data";
import { inject } from "@angular/core";
import { UserPropertiesService } from "./services/user-properties.service";
import { EMPTY, catchError } from "rxjs";

export const determinator = (property: any): PropertySearchType => {
    if ("landType" in property) {
        return PropertySearchType.Land;
    }
    if ("industrialHall" in property) {
        return PropertySearchType.Industrial;
    }
    if ("isKiosk" in property) {
        return PropertySearchType.Commercial;
    }
    if ("dvCabling" in property) {
        return PropertySearchType.Office;
    }
    if ("story" in property) {
        return PropertySearchType.Apartment;
    }
    return PropertySearchType.House;
}



export const propertyResolver: ResolveFn<BaseProperty> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const id = route.queryParams['id'];
        const type = route.queryParams['type'] + 's' as PropertySearchType;
        const router = inject(Router);

        if (!id || !type) {
            router.navigate(["/"]);
            return EMPTY;
        }

        return inject(UserPropertiesService).getProperty(id, type).pipe(catchError(err => {
            router.navigate(["/"]);
            return EMPTY;
        }));;
    };



export const getPageSize = () => {
    let pageSize = localStorage.getItem("pageSize");
    if (!pageSize) {
        pageSize = "10";
        localStorage.setItem("pageSize", "10");
    }
    return pageSize;
}

export const setPageSize = (size: number)=>{
    localStorage.setItem("pageSize", size.toString());
}