import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/modules/angular-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { LogoTemplateComponent } from './components/logo-template/logo-template.component';
import { RouterModule } from '@angular/router';
import { AccordionComponent } from './components/accordion/accordion.component';

@NgModule({
  declarations: [
    SvgIconComponent,
    LogoTemplateComponent,
    AccordionComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    FormsModule 
  ],
  exports:[
    SvgIconComponent,
    LogoTemplateComponent,
    AccordionComponent
  ]
})
export class SharedModule { }
