<footer>
    <div class="footer-container">
        <div class="d-flex flex-column mx-auto py-4" style="width:80%">
            <div class="footer-responsive">
                <div class="align-self-center">
                    <div class="presentation">
                        <app-logo-template></app-logo-template>
                        <p>{{"footer.description" | translate}}</p>
                    </div>
                </div>
                
                <ng-container *ngTemplateOutlet="accordions"></ng-container>
                <ng-container>
                    <div class="accordion-not-visible">
                        <p class="h5" style="font-weight:600; text-decoration: underline;">{{terms.header}}</p>
                        <ul style="list-style:none; cursor:pointer" class="m-0 p-0">
                            <li class="my-2" *ngFor="let term of terms.items; let i = index;">
                                <a [routerLink]="term.routerLink">{{ term.text | translate }}</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="accordion-not-visible">
                        <p class="h5" style="font-weight:600; text-decoration: underline;">{{links.header}}</p>
                        <ul style="list-style:none; cursor:pointer" class="m-0 p-0">
                            <li class="my-2" *ngFor="let link of links.items; let i = index;">
                                <a [routerLink]="link.routerLink">{{ link.text| translate }}</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="accordion-not-visible">
                        <p class="h5" style="font-weight:600; text-decoration: underline;">{{ account.header | translate}}</p>
                        <ul style="list-style:none; cursor:pointer" class="m-0 p-0">
                            <li class="my-2" *ngFor="let item of account.items; let i = index;">
                                <a *ngIf="item.isVisible" [routerLink]="item.routerLink">{{ item.text| translate }}</a>
                            </li>
                        </ul>
                    </div>
                </ng-container>
                
            </div>
            <div class="d-flex justify-content-center mt-5 p-0">
                <div class="">
                    <a [routerLink]="['/']" class="m-0 p-0">
                        <img src="../../../../assets/logos/fb.png" alt="Icon">
                    </a>
                </div>
                <div class="">
                    <a [routerLink]="['/']" class="m-0 p-0">
                        <img src="../../../../assets/logos/instagram.png" alt="Icon">
                    </a>
                </div>
            </div>
            <div class="row text-center"><small class="text-center mt-2">&copy; imoLimo, 2024.
                    {{"footer.all-rights-reserved" | translate}}</small></div>
        </div>
    </div>
</footer>

<ng-template #accordions>
    <div class="d-block">
        <app-accordion class="accordion-visible" [structure]="terms"></app-accordion>
        <app-accordion class="accordion-visible" [structure]="account"></app-accordion>
        <app-accordion class="accordion-visible" [structure]="links"></app-accordion>
    </div>
</ng-template>