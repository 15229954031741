import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthTokenService } from './modules/authentication/services/auth-token.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean = false;
  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private authTokenService: AuthTokenService,
    private translateService: TranslateService,
    private ccService: NgcCookieConsentService
  ) {

  }
  
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.authTokenService.checkLoginEvent$.subscribe((data) => {
      this.isLoggedIn = data;
    });

    this.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.subs.push(
          this.translateService
            .get(['cookie.message', 'cookie.dismiss', 'cookie.link'])
            .subscribe((data) => {
              this.ccService.getConfig().content =
                this.ccService.getConfig().content || {};
              // Override default messages with the translated ones
              this.ccService.getConfig().content!.message =
                data['cookie.message'];
              this.ccService.getConfig().content!.dismiss =
                data['cookie.dismiss'];
              this.ccService.getConfig().content!.link = data['cookie.link'];

              this.ccService.destroy(); // remove previous cookie bar (with default messages)
              this.ccService.init(this.ccService.getConfig()); // update config with translated messages
            })
        );
      })
    );
  }

  logout(): void {
    this.authTokenService.logout();
    this.router.navigate(['/user-conectivity']);
  }

  addProperty(): void {
    if (this.isLoggedIn) {
      this.router.navigate(['/add-property']);
    } else if (!this.isLoggedIn) {
      this.router.navigate(['/user-conectivity']);
    }
  }

  changeLanguage(lang: string): void {
    this.translateService.use(lang).subscribe(() => {
      localStorage.setItem('lang', lang);
    });
  }

  currentLanguage() {
    return this.translateService.currentLang;
  }
}
