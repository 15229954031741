import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ApartmentDTO,
  HouseDTO,
  LandDTO,
  OfficeDTO,
  IndustrialDTO,
  CommercialDTO,
  PropertyType,
  ImageDTO,
  QueryPaginationDTO,
  UserDTO,
  PropertySearchType,
  LandQueryDTO,
  HouseQueryDTO,
  ApartmentQueryDTO,
  SortType,
  LocationDTO,
  BaseProperty,
  FavoriteProperty,
  UserPropertyInfo,
  PaginatedProps,
  RegisterUserDTO,
} from '../modules/app-user-dashboard/data';
import { determinator } from '../modules/app-user-dashboard/util';
@Injectable({
  providedIn: 'root',
})
export class BackendEndPointService {
  private url = environment.apiURL;

  queryPaginator: QueryPaginationDTO = {
    page: 0,
    pageSize: 100,
    sort: SortType.descending,
    sortedByField: 'createdAt',
  };
  constructor(private http: HttpClient) {}

  registerUser(user: RegisterUserDTO): Observable<any> {
    return this.http.post<any>(this.url + '/auth/register', user);
  }

  confirmEmail(tokenId: string): Observable<any> {
    return this.http.post<any>(this.url + '/auth/confirm/action/' + tokenId, {
      tokenId,
    });
  }

  loginUser(email: string, password: string): Observable<any> {
    const body = {
      email: email,
      password: password,
    };
    return this.http.post<any>(this.url + '/auth/login', body);
  }

  lostPassword(email: string): Observable<any> {
    const body = {
      email: email,
    };
    return this.http.post<any>(this.url + '/auth/lost_password', body);
  }

  resendConfimation(email: string): Observable<any> {
    const body = {
      email: email,
    };
    return this.http.post<any>(this.url + '/auth/resend_confirmation', body);
  }

  resetPassword(tokenId: string, newPassword: string): Observable<any> {
    const body = {
      newPassword: newPassword,
    };
    return this.http.post<any>(
      this.url + '/auth/reset_password/' + tokenId,
      body
    );
  }

  updatePassword(newPassword: string, token: string): Observable<any> {
    const body = {
     password: newPassword
    };
    return this.http.put<any>(this.url + '/user', body, {
      headers: { Authorization: 'Bearer ' + token },
    });
  }

  refreshToken(refreshToken: string): Observable<any> {
    const header = {
      'refresh-token': refreshToken,
    };
    return this.http.post<any>(
      this.url + '/auth/token',
      {},
      {
        headers: header,
      }
    );
  }

  updateUser(user: UserPropertyInfo, token: string): Observable<any> {
    const body = {
      name: user.name,
      email: user.email,
      contact: user.agent ? user.company?.companyPhone : user.contact,
    };
    return this.http.put<any>(this.url + '/user', body, {
      headers: { Authorization: 'Bearer ' + token },
    });
  }

  deleteUser(token: string): Observable<any> {
    return this.http.delete<any>(this.url + '/user', {
      headers: { Authorization: 'Bearer ' + token },
    });
  }

  getUserById(id: string): Observable<any> {
    return this.http.get<any>(this.url + '/user/' + id);
  }

  getUserInfo(token: string): Observable<UserPropertyInfo> {
    return this.http.get<UserPropertyInfo>(this.url + '/user-info', {
      headers: { Authorization: 'Bearer ' + token },
    });
  }

  //IMAGES
  getImageId(propertyType: PropertyType, id: string): Observable<any> {
    return this.http.get<any>(
      this.url + '/images/' + propertyType + '/' + id + '/images'
    );
  }

  addImage(
    propertyType: PropertyType,
    id: string,
    image: any,
    token: string
  ): Observable<any> {
    const propertyTypeUpperCase = propertyType.toUpperCase();
    return this.http.post(
      this.url + '/images/' + propertyTypeUpperCase + '/' + id + '/images',
      image,
      { headers: { Authorization: 'Bearer ' + token }, responseType: 'text' }
    );
  }

  getImage(imageName: string): Observable<any> {
    let queryParams = { imageName };
    return this.http.get<any>(this.url, { params: queryParams });
  }

  deleteImage(
    propertyType: PropertyType,
    id: string,
    imageName: string,
    token: string
  ): Observable<any> {
    return this.http.delete(
      this.url + '/images/' + propertyType + '/' + id + '/images/' + imageName,
      { headers: { Authorization: 'Bearer ' + token } }
    );
  }

  //Search
  getSearchOffices(query: Partial<any>, page: number, pageSize: number) {
    return this.http.get<any>(this.url + '/search/offices', {
      params: { ...query, page, pageSize },
    });
  }

  getSearchLands(query: Partial<LandQueryDTO>, page: number, pageSize: number) {
    return this.http.get<any>(this.url + '/search/land', {
      params: { ...query, page, pageSize },
    });
  }

  getSearchIndustrials(query: Partial<any>, page: number, pageSize: number) {
    return this.http.get<any>(this.url + '/search/industrial', {
      params: { ...query, page, pageSize },
    });
  }

  getSearchHouses(
    query: Partial<HouseQueryDTO>,
    page: number,
    pageSize: number
  ) {
    return this.http.get<any>(this.url + '/search/house', {
      params: { ...query, page, pageSize },
    });
  }

  getSearchCommercials(query: Partial<any>, page: number, pageSize: number) {
    return this.http.get<any>(this.url + '/search/commercial', {
      params: { ...query, page, pageSize },
    });
  }

  getSearchApartments(
    query: Partial<ApartmentQueryDTO>,
    page: number,
    pageSize: number
  ) {
    return this.http.get<any>(this.url + '/search/apartment', {
      params: { ...query, page, pageSize },
    });
  }

  searchLocation(location: string): Observable<LocationDTO[]> {
    const body = {
      ...this.queryPaginator,
      name: location,
    };
    return this.http
      .get<any>(this.url + '/search/locations', { params: body })
      .pipe(map((value) => value.content));
  }

  addProperty(
    property: BaseProperty,
    type: string,
    token: string
  ): Observable<any> {
    return this.http.post(this.url + `/${type}`, property, {
      headers: { Authorization: 'Bearer ' + token },
    });
  }

  getProperty(
    id: string,
    type: PropertySearchType,
    token?: string
  ): Observable<BaseProperty> {
    return this.http.get<any>(
      this.url + '/' + type.toLowerCase() + '/' + id,
      token
        ? {
            headers: { Authorization: 'Bearer ' + token },
          }
        : {}
    );
  }

  getProperties(
    type: PropertySearchType,
    count?: number
  ): Observable<BaseProperty[]> {
    return this.http
      .get<any>(this.url + '/' + type.toLowerCase(), {
        params: {
          ...this.queryPaginator,
          pageSize: count ?? this.queryPaginator.pageSize,
        },
      })
      .pipe(map((res: any) => res.content));
  }

  getAllProperties(
    type: PropertySearchType,
    page: number,
    pageSize: number
  ): Observable<BaseProperty[]> {
    return this.http
      .get<any>(this.url + '/' + type.toLowerCase(), {
        params: { page, pageSize },
      })
      .pipe(map((res: any) => res.content));
  }

  getUserProperties(
    type: PropertySearchType,
    token: string,
    page: number,
    pageSize: number
  ): Observable<PaginatedProps> {
    return this.http
      .post<any>(
        this.url + '/user/' + type.toUpperCase().slice(0, -1),
        {},
        {
          headers: { Authorization: 'Bearer ' + token },
          params: { page, pageSize },
        }
      )
      .pipe(
        map((res: any) => {
          return {
            props: res.content,
            totalElements: res.totalElements,
          };
        })
      );
  }

  updateProperty(property: BaseProperty, token: string): Observable<any> {
    const type = determinator(property);
    return this.http.put(
      this.url + '/' + type.toLowerCase() + '/' + property.id,
      property,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  deleteProperty(property: BaseProperty, token: string): Observable<any> {
    const type = determinator(property);
    return this.http.delete<any>(
      this.url + '/' + type.toLowerCase() + '/' + property.id,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  getPropertyImages(property: BaseProperty): Observable<any[]> {
    const type = determinator(property);

    const headers = new HttpHeaders({ 'Cache-Control': 'max-age=3600' });
    return this.http.get<any[]>(
      this.url +
        '/images/' +
        type.toUpperCase().slice(0, -1) +
        '/' +
        property.id +
        '/images'
    , { headers });
  }

  deletePropertyImage(
    property: BaseProperty,
    imageName: string,
    token: string
  ): Observable<any> {
    const type = determinator(property);
    return this.http.delete(
      this.url +
        '/images/' +
        type.toUpperCase().slice(0, -1) +
        '/' +
        property.id +
        '/images/' +
        imageName,
      { headers: { Authorization: 'Bearer ' + token }, responseType: 'text' }
    );
  }

  addFavorite(id: string, type: string, token: string) {
    const propertyTypeUpperCase = type.toUpperCase().slice(0, -1);
    return this.http.post(
      this.url + '/favorites/' + propertyTypeUpperCase + '/' + id,
      {},
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  deleteFavorite(id: string, type: string, token: string) {
    const propertyTypeUpperCase = type.toUpperCase().slice(0, -1);
    return this.http.delete(
      this.url + '/favorites/' + propertyTypeUpperCase + '/' + id,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  getFavorites(
    type: PropertySearchType,
    token: string
  ): Observable<FavoriteProperty[]> {
    return this.http
      .get<any>(this.url + '/favorites/' + type.toLowerCase(), {
        params: { ...this.queryPaginator },
        headers: { Authorization: 'Bearer ' + token },
      })
      .pipe(
        map((res) =>
          res.content.map((el: any) => {
            const ftype = type.toLowerCase().slice(0, -1);
            return { user: el.user, id: el.id, property: el[ftype] };
          })
        )
      );
  }
}
