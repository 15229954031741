<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content>
    <app-navbar [currentLanguage]="currentLanguage()" (changeLanguageEmitter)="changeLanguage($event)"
      (addPropertyEmitter)="addProperty()" (logoutEmitter)="logout()" class="navbar-section"
      [isLoggedIn]="isLoggedIn"></app-navbar>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
    <div class="footer">
      <app-footer [isLoggedIn]="isLoggedIn"></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>