import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.css']
})
export class SvgIconComponent implements OnInit {
  @Input() src: string = '';
  @Input() propertyType: string = '';
  @Input() isActive: boolean = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
   
  }
  ngOnInit(): void {
    this.matIconRegistry.addSvgIcon(
      `${this.propertyType}`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${this.src}.svg`)
    );
  }
}
