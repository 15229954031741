import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './modules/angular-material.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserService } from './modules/app-user-dashboard/services/user.service';
import { AuthTokenService } from './modules/authentication/services/auth-token.service';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { CoreModule } from './modules/core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuardService } from './modules/authentication/services/auth-guard.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost',
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#ff6600',
      text: 'ffffff',
    },
  },
  content: {
    href: 'privacy-policy',
  },
  theme: 'classic',
  type: 'info',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    MaterialModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
    }),
  ],
  providers: [UserService, AuthTokenService, AuthGuardService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService) {
    let savedLang = localStorage.getItem('lang');
    if (!savedLang) {
      localStorage.setItem('lang', 'ro');
      savedLang = 'ro';
    }
    translate.setDefaultLang(savedLang);
    translate.use(savedLang);
  }
}
