import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent {
  @Input() structure: { id: string; header: string; items: { routerLink: string, text: string, isVisible: boolean }[] } = 
  { id: '', header: '', items: [] };

  
  toggleAccordion(index: number): void {
    //this.items[index].expanded = !this.items[index].expanded;
  }
}
