import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthTokenService } from '../../authentication/services/auth-token.service';
import { UserDTO, UserPropertyInfo } from '../data';
import { BackendEndPointService } from 'src/app/services/back-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  beURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private authTokenService: AuthTokenService,
    private backEndEndPointService: BackendEndPointService
  ) {}

  getUserProfile(): Observable<any> {
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.getUserInfo(token);
      })
    );
  }

  updateUserDetails(userDetails: UserDTO): Observable<any> {
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.updateUser(userDetails, token);
      })
    );
  }

  changeUserPassword(newPassword:string): Observable<any> {
    
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.updatePassword(newPassword, token);
      })
    );
  }

  resetUserPassword(token: string, newPassword: string): Observable<any> {
    return this.backEndEndPointService.resetPassword(token, newPassword);
  }

  deleteUser(): Observable<Object> {
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.deleteUser(token);
      })
    );
  }

  getUserInfo(): Observable<UserPropertyInfo> {
    return this.authTokenService.getAccessToken().pipe(
      switchMap((token) => {
        return this.backEndEndPointService.getUserInfo(token);
      })
    );
  }
}
