<nav class="navbar navbar-expand-lg" style="background-color: #f5f5f5;">
  <div class="container">
    <app-logo-template></app-logo-template>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 actions-container">
        <li class="nav-item">
          <button mat-button [matMenuTriggerFor]="languageMenu">
            <div class="language">
              <img class="flag-icon" src="assets/images/{{ currentLanguage }}.png" />
              {{ "app.language." + currentLanguage | translate }}
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
          </button>

          <mat-menu class="mat-menu" #languageMenu="matMenu">
            <button mat-menu-item (click)="changeLanguage('ro')" *ngIf="currentLanguage !== 'ro'">
              <div class="language">
                <img class="flag-icon" src="assets/images/ro.png" />{{ "app.language.ro" | translate }}
              </div>
            </button>
            <button mat-menu-item (click)="changeLanguage('en')" *ngIf="currentLanguage !== 'en'">
              <div class="language">
                <img class="flag-icon" src="assets/images/en.png" />{{ "app.language.en" | translate }}
              </div>
            </button>
            <button mat-menu-item (click)="changeLanguage('de')" *ngIf="currentLanguage !== 'de'">
              <div class="language">
                <img class="flag-icon" src="assets/images/de.png" />{{ "app.language.de" | translate }}
              </div>
            </button>
          </mat-menu>
        </li>
        <li class="nav-item">
          <button mat-stroked-button color="accent" style="color: #ff6600" (click)="addProperty()">
            {{ "app.add-property" | translate }}
          </button>
        </li>
        <li class="nav-item auth-actions">
          <ng-container *ngIf="isLoggedIn; then loggedTemplate; else notLoggedTemplate"></ng-container>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #notLoggedTemplate>
  <button mat-flat-button color="accent" [routerLink]="['/authentication/login-page']" class="button-text-color mr-2">
    {{ "app.profile.log-in" | translate }}
  </button>

  <button mat-flat-button color="accent" [routerLink]="['/authentication/register-page']" class="button-text-color">
    {{ "authentication.register.buton-text" | translate }}
  </button>
</ng-template>

<ng-template #loggedTemplate>
  <button mat-flat-button color="accent" #profileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="profileMenu"
    aria-label="Profile" class="button-text-color">
    <mat-icon>person</mat-icon>
    {{ "app.profile.button-text" | translate }}
  </button>
  <mat-menu #profileMenu="matMenu">
    <button mat-menu-item [routerLink]="['profile']">
      {{ "app.profile.my-profile" | translate }}
    </button>

    <button mat-menu-item [matMenuTriggerFor]="properties">
      {{ "app.profile.my-properties" | translate }}
    </button>
    <mat-menu #properties="matMenu">
      <button mat-menu-item [routerLink]="['user-properties/apartments']">
        <mat-icon>apartment</mat-icon>
        <span>{{ "app.profile.my-apartments" | translate }}</span>
      </button>
      <button mat-menu-item [routerLink]="['user-properties/houses']">
        <mat-icon>house</mat-icon>
        <span>{{ "app.profile.my-houses" | translate }}</span>
      </button>

      <button mat-menu-item [routerLink]="['user-properties/lands']">
        <mat-icon>landscape</mat-icon>
        <span>{{ "app.profile.my-lands" | translate }}</span>
      </button>

      <button mat-menu-item [routerLink]="['user-properties/offices']">
        <mat-icon>business_center</mat-icon>
        <span>{{ "app.profile.my-offices" | translate }}</span>
      </button>

      <button mat-menu-item [routerLink]="['user-properties/commercials']">
        <mat-icon>shopping_cart</mat-icon>
        <span>{{ "app.profile.my-commercials" | translate }}</span>
      </button>

      <button mat-menu-item [routerLink]="['user-properties/industrials']">
        <mat-icon>build</mat-icon>
        <span>{{ "app.profile.my-industrials" | translate }}</span>
      </button>
    </mat-menu>
    <button mat-menu-item [routerLink]="['user-favorites']">
      {{ "app.profile.favorites" | translate }}
    </button>

    <button mat-menu-item (click)="logout()">
      {{ "app.profile.log-out" | translate }}
    </button>
  </mat-menu>
</ng-template>