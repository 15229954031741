<div class="accordion accordion-flush accordion-extra" id="accordion">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
              [attr.data-bs-target]="'#flush-' + structure.id" aria-expanded="false" [attr.aria-controls]="'#flush-' + structure.header">
        {{structure.header | translate}}
      </button>
    </h2>
    <div [id]="'flush-' + structure.id" class="accordion-collapse collapse" 
         aria-labelledby="flush-headingOne" [attr.data-bs-parent]="'#flush-' + structure.id">
      <div class="accordion-body">
        <ul style="list-style:none; cursor:pointer" class="m-0 p-0">
            <li class="my-2" *ngFor="let item of structure.items; let i = index;">
                <a *ngIf="item.isVisible" [routerLink]="item.routerLink">{{ item.text | translate }}</a>
            </li>
        </ul>
      </div>
    </div>
  </div>
</div>


<!-- <div class="accordion">
  <div class="accordion-item">
    <div class="accordion-header">
      {{ structure.header }}
    </div>
    <div class="accordion-content">
      <p class="h5" style="font-weight:600; text-decoration: underline;">{{structure.header}}</p>
      <ul style="list-style:none; cursor:pointer" class="m-0 p-0">
          <li class="my-2" *ngFor="let item of structure.items; let i = index;">
              <a [routerLink]="item.text">{{ item.text | translate }}</a>
          </li>
      </ul>
    </div>
  </div>
</div> -->
