import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { Router } from '@angular/router';
import { BackendEndPointService } from 'src/app/services/back-endpoint.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  private accessToken: string | undefined;
  private refreshToken: string | undefined;
  public userIsLogin: boolean = false;
  private loginEvent = new BehaviorSubject<boolean>(false);
  checkLoginEvent$ = this.loginEvent.asObservable();

  constructor(
    private router: Router,
    private backEndEndPointService: BackendEndPointService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    this.tryToLogin((data, error) => this.tryToLoginCallback(data, error));
  }

  // Method used as callback response after user tries to login
  private tryToLoginCallback(data: any, error: string | null) {
    if (error !== null) {
      console.error(error);
    }
    if (data !== null) {
      this.setTokens(data);
      this.userIsLogin = true;
      this.loginEvent.next(true);
    }
  }

  private tryToLogin(cb: (data: any, error: string | null) => void) {
    let refreshToken = this._getRefreshToken();
    if (refreshToken !== null) {

      this.backEndEndPointService.refreshToken(refreshToken)
        .subscribe((response: any) => {
          if (response.error) {
            cb(null, response.error.message);
          }
          else {
            cb(response, null);
          }
        });
    }
    else {
      cb(null, 'no refresh token');
    }
  }

  public userLogin(email: string, password: string) {
    this.backEndEndPointService
      .loginUser(email, password)
      .pipe(
        catchError((error: any, caught: Observable<any>): Observable<any> => {
          this.userIsLogin = false;
          this.loginEvent.next(false);
          this.toastr.error(this.translateService.instant('authentication.login.login-failed'));
          return of();
        })
      )
      .subscribe((value: any) => {
        this.setTokens(value);
        this.userIsLogin = true;
        this.loginEvent.next(true);
        this.router.navigate(['/']);
        this.toastr.success(this.translateService.instant('authentication.login.login-succeed'));
      });
  }

  public logout() {
    localStorage.clear();
    this.accessToken = undefined;
    this.refreshToken = undefined;
    this.userIsLogin = false;
    this.loginEvent.next(false);
  }

  private setTokens(tokens: {
    accessToken: string;
    accessTokenValidTime: number;
    refreshToken: string;
    refreshTokenValidTime: number;
  }) {
    this.accessToken = tokens.accessToken;

    this.refreshToken = tokens.refreshToken;
    localStorage.setItem('refresh_token', tokens.refreshToken);
  }

  private _getRefreshToken(): string | null {
    let token = this.refreshToken;
    if (token === undefined) {
      let token = localStorage.getItem('refresh_token');
      if (token !== null) {
        return token;
      } else {
        return null;
      }
    } else {
      return token;
    }
  }

  public getAccessToken(): Observable<string> {
    return new Observable((observer) => {
      if (!this.userIsLogin) {
        observer.error('User is not logged in');
      } else if (
        this.accessToken !== undefined &&
        this.isJwtExpiredOrInvalid(this.accessToken)
      ) {
        this.tryToLogin((data, error) => {
          if (error !== null) {
            observer.error(error);
          }
          if (data !== null) {
            this.setTokens(data);
            this.userIsLogin = true;
            this.loginEvent.next(true);
            observer.next(data.accessToken);
          }
          observer.complete();
        });
      } else {
        observer.next(this.accessToken);
        observer.complete();
      }
    });
  }

  private isJwtExpiredOrInvalid(token: string): boolean {
    if (typeof token !== 'string' || !token) {
      return true;
    }
    let isJwtExpired = false;
    const decoded: any = jwtDecode(token);
    const currentTime = new Date().getTime() / 1000;
    if (currentTime > decoded.exp) {
      isJwtExpired = true;
    }
    return isJwtExpired;
  }
}
