import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() isLoggedIn: boolean = false;
  
  @Input() currentLanguage: string = '';
  @Output() changeLanguageEmitter = new EventEmitter<string>();
  @Output() addPropertyEmitter = new EventEmitter();
  @Output() logoutEmitter = new EventEmitter();

  changeLanguage(language: string){
    this.changeLanguageEmitter.emit(language);
  }

  addProperty(): void {
    this.addPropertyEmitter.emit();
  }

  logout(){
    this.logoutEmitter.emit();
  }
}
