import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class MatPaginatorIntlCro extends MatPaginatorIntl {
    constructor(private translateService: TranslateService) {
        super();
        this.itemsPerPageLabel = this.translateService.instant("app-user-dashboard.items-per-page");
        this.nextPageLabel = this.translateService.instant("app-user-dashboard.next-page");
        this.previousPageLabel = this.translateService.instant("app-user-dashboard.prev-page");
        this.translateService.onLangChange.subscribe(() => {
            this.itemsPerPageLabel = this.translateService.instant("app-user-dashboard.items-per-page");
            this.nextPageLabel = this.translateService.instant("app-user-dashboard.next-page");
            this.previousPageLabel = this.translateService.instant("app-user-dashboard.prev-page");
            this.changes.next();

        });
    }

    
}